// @ts-check

/**
 * @param {number} paymentAmount
 * @param {string} currency
 * @param {'card' | 'us_bank_account'} paymentMethod
 */
const getPaymentBreakdown = (paymentAmount, currency, paymentMethod) => {
	const elevateFee = getElevateFee(paymentAmount, currency)
	const stripeFee = getStripeFee(paymentAmount, paymentMethod)
	const totalFee = elevateFee + stripeFee
	const totalAmount = paymentAmount + totalFee
	return {
		elevateFee,
		stripeFee,
		totalFee,
		totalAmount,
	}
}

/**
 * @param {number} paymentAmount
 * @param {'card' | 'us_bank_account'} paymentMethod
 */
const getStripeFee = (paymentAmount, paymentMethod) => {
	switch (paymentMethod) {
		case 'card':
			// Card fee: 2.9% + $0.30
			return 0.029 * paymentAmount + 0.3
		case 'us_bank_account':
			// ACH fee: 0.8%, max $5
			return Math.min(0.008 * paymentAmount, 5)
		default:
			throw new Error(`Unexpected payment method: ${paymentMethod}`)
	}
}

/**
 * @param {number} paymentAmount
 * @param {string} currency
 */
const getElevateFee = (paymentAmount, currency) => {
	//TODO: Set Maximum of 5 USD. Needs currency conversion
	if (currency.toLowerCase() === 'usd')
		return Math.min(paymentAmount * 0.005, 5)
	else return paymentAmount * 0.005
}

/** @param {number} value */
const roundToCents = (value) => Math.round(value * 100) / 100

module.exports = {
	getElevateFee,
	getPaymentBreakdown,
	getStripeFee,
	roundToCents,
}
