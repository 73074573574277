import { Fragment, useEffect, useState } from 'react'
import {
	Alert,
	Button,
	Col,
	Form,
	Row,
	Spinner,
	InputGroup,
	Table,
} from 'react-bootstrap'
import { addWeeks, formatISO } from 'date-fns'
import useAuth, { useCustomerId } from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'
import CustomerInfoCard from './company.info.card.component'
import {
	useEpicorCustomerService,
	useEpicorQuoteService,
	useEpicorSharedService,
} from 'services/epicor'
import LoadingCards from '../../shared/loading/loading.card.component'
import AsyncPartTypeahead from '../../shared/part.search.component'
import _ from 'lodash'

const NewQuote = ({ onSubmitted }) => {
	const { currentCompany } = useAuth()
	const { settings } = useSettings()
	const EpicorCustomerService = useEpicorCustomerService()
	const EpicorQuoteService = useEpicorQuoteService()
	const EpicorSharedService = useEpicorSharedService()
	const orderDate = formatISO(new Date(), { representation: 'date' })
	const [needByDate, setNeedByDate] = useState(
		formatISO(addWeeks(new Date(), 2), { representation: 'date' }),
	)
	const [error, setError] = useState(null)
	const [poNumber, setPoNumber] = useState('')
	const [comments, setComments] = useState('')
	const [lineItems, setLineItems] = useState([
		{
			ident: new Date().getTime().toString(36),
			part: '',
			description: '',
			qty: 1,
			unitPrice: 0,
			needByDate,
		},
	])
	const [currentCustomerAddress, setCurrentCustomerAddress] = useState(null)
	const [shipToNum, setShipToNum] = useState('')
	const [shipToAddresses, setShipToAddresses] = useState([])
	const [shipVias, setShipVias] = useState([])
	const [shipViaCode, setShipViaCode] = useState('')
	const [loadingCCInfo, setLoadingCCInfo] = useState(true)
	const [savingQuote, setSavingQuote] = useState(false)

	const customerId = useCustomerId()

	const createQuotePayload = () => {
		const buildLineItems = () => {
			return lineItems.map((item) => {
				return {
					part: item.part,
					qty: item.qty,
					needByDate: item.needByDate,
				}
			})
		}
		return {
			quote: {
				poNumber: poNumber,
				shipTo: shipToNum,
				date: orderDate,
				needByDate: needByDate,
				comment: comments,
				shipViaCode: shipViaCode,
				lineItems: buildLineItems(),
			},
		}
	}

	const addLineItem = () => {
		const newItems = [
			...lineItems,
			{
				ident: new Date().getTime().toString(36),
				part: '',
				description: '',
				qty: 1,
				unitPrice: 0,
				needByDate: needByDate,
			},
		]
		setLineItems(newItems)
	}

	const submit = async (event) => {
		event.preventDefault()
		setSavingQuote(true)

		try {
			const response = await EpicorQuoteService.create(
				createQuotePayload(),
				currentCompany.company.environmentId,
				currentCompany.company.company1,
				customerId,
			)
			const { error } = response.data
			if (error) {
				setError(error[0]?.ErrorText ?? JSON.stringify(error))
			} else {
				onSubmitted(response.data)
			}
		} catch (error) {
			setError(error.message)
		} finally {
			setSavingQuote(false)
		}
	}

	const ShipToOptions = () => {
		return shipToAddresses.map((address) => (
			<option key={address.shipToNum} value={address.shipToNum}>
				{address.name}-{address.address1}
			</option>
		))
	}

	const ShipViaOptions = () => {
		return shipVias.map((shipVia) => (
			<option key={shipVia.shipViaCode} value={shipVia.shipViaCode}>
				{shipVia.description}
			</option>
		))
	}

	const updateLineItem = (lineItem, field, value) => {
		const index = lineItems.findIndex((item) => item.ident === lineItem.ident)
		if (index !== -1) {
			setLineItems((items) => {
				const newLineItems = [...items]
				newLineItems[index] = { ...items[index], [field]: value }
				return newLineItems
			})
		}
	}

	const updatePartSelection = (selectedPart, lineItem) => {
		if (selectedPart) {
			updateLineItem(lineItem, 'part', selectedPart.partNum)
			updateLineItem(lineItem, 'description', selectedPart.partDescription)
			getPriceByQty(lineItem, selectedPart.partNum)
		} else {
			updateLineItem(lineItem, 'description', '')
		}
	}

	const getPriceByQty = (
		lineItem,
		part = lineItem.part,
		qty = lineItem.qty,
	) => {
		EpicorSharedService.itemPriceByQty(customerId, shipToNum, part, qty)
			.then((response) => {
				updateLineItem(lineItem, 'unitPrice', response.data.basePrice)
				setError(null)
			})
			.catch((error) => {
				setError('An error ocurred, please check the part on the line item')
			})
	}

	useEffect(() => {
		EpicorCustomerService.customerInfo(customerId)
			.then((response) => {
				const {
					customerInfo,
					shipToAddresses: addresses,
					address,
				} = response.data
				setCurrentCustomerAddress(address)
				setShipToAddresses(addresses.sort((a, b) => a.name > b.name))
				setShipViaCode(customerInfo.ShipVia)
			})
			.catch((error) => {
				setError(error.message)
			})

		EpicorSharedService.shipViaList(
			currentCompany.companyId,
			currentCompany.company.environmentId,
		)
			.then((response) => {
				setShipVias(response.data)
			})
			.catch((error) => {
				setError(error.message)
			})
			.finally(() => setLoadingCCInfo(false))
	}, [EpicorCustomerService, EpicorSharedService, currentCompany, customerId])

	return (
		<Form onSubmit={submit}>
			<Row>
				{loadingCCInfo ? (
					<LoadingCards cant={2} />
				) : (
					<>
						{currentCustomerAddress && (
							<Col>
								<CustomerInfoCard
									info={currentCustomerAddress}
									title="Bill To"
								/>
							</Col>
						)}
						{!_.isEmpty(shipToAddresses) &&
							shipToAddresses.find((item) => item.shipToNum === shipToNum) && (
								<Col>
									<CustomerInfoCard
										info={shipToAddresses.find(
											(item) => item.shipToNum === shipToNum,
										)}
										title="Ship To"
									/>
								</Col>
							)}
					</>
				)}
			</Row>
			<Row>
				<Col className="d-grid gap-4 mt-4">
					<h6>
						<strong>Quote Details</strong>
					</h6>
					<Form.Group controlId="form.shipTo">
						<Form.Label>Shipping Address</Form.Label>
						<Form.Select
							name="shipTo"
							onChange={(e) => setShipToNum(e.target.value)}
							value={shipToNum || ''}
						>
							<ShipToOptions />
						</Form.Select>
					</Form.Group>
					<Form.Group controlId="form.needBy">
						<Form.Label>Need By</Form.Label>
						<Form.Control
							name="needBy"
							required
							type="date"
							value={needByDate}
							min={formatISO(new Date(), { representation: 'date' })}
							onChange={(e) => setNeedByDate(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="form.po">
						<Form.Label>PO Number</Form.Label>
						<Form.Control
							name="po"
							type="string"
							placeholder="PO #"
							value={poNumber}
							onChange={(e) => setPoNumber(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="form.orderDate">
						<Form.Label>Order Date</Form.Label>
						<Form.Control
							name="orderDate"
							type="date"
							value={orderDate}
							disabled
						/>
					</Form.Group>
					<Form.Group controlId="form.shipVia">
						<Form.Label>Ship Via</Form.Label>
						<Form.Select
							name="shipVia"
							onChange={(e) => setShipViaCode(e.target.value)}
							value={shipViaCode || ''}
						>
							<ShipViaOptions />
						</Form.Select>
					</Form.Group>
					<Form.Group controlId="form.comments">
						<Form.Label>Comments (optional)</Form.Label>
						<Form.Control
							as="textarea"
							rows={3}
							name="comments"
							placeholder="Please, put your comments here."
							value={comments}
							onChange={(e) => setComments(e.target.value)}
						/>
					</Form.Group>
				</Col>
				<Col className="d-flex flex-column">
					<Table>
						<thead>
							<tr>
								<th>Part</th>
								<th>Qty</th>
								<th>Price</th>
								<th>Ext Price</th>
								<th>Need By</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							{lineItems.map((lineItem) => (
								<Fragment key={lineItem.ident}>
									<tr className="position-relative">
										<td className="border-bottom-0">
											<Form.Group controlId="form.part.search">
												<InputGroup className="position-unset">
													<AsyncPartTypeahead
														ident={lineItem.ident}
														onChange={(selected) =>
															updatePartSelection(selected, lineItem)
														}
													/>
												</InputGroup>
											</Form.Group>
										</td>
										<td className="border-bottom-0">
											<Form.Group>
												<Form.Control
													name="qty"
													type="number"
													min={1}
													value={lineItem.qty}
													onChange={(e) => {
														updateLineItem(lineItem, 'qty', e.target.value)
														getPriceByQty(lineItem)
													}}
												/>
											</Form.Group>
										</td>
										<td className="border-bottom-0">
											{'$' + lineItem.unitPrice}
										</td>
										<td className="border-bottom-0">
											{'$' + (lineItem.qty * lineItem.unitPrice).toFixed(2)}
										</td>
										<td className="border-bottom-0">
											<Form.Group controlId="form.needBy">
												<Form.Control
													name="needByLine"
													type="date"
													value={lineItem.needByDate}
													min={formatISO(new Date(), {
														representation: 'date',
													})}
													onChange={(e) =>
														updateLineItem(
															lineItem,
															'needByDate',
															e.target.value,
														)
													}
												/>
											</Form.Group>
										</td>
										<td className="border-bottom-0">
											<Button
												variant="outline-danger"
												className="bi-x-square"
												onClick={() =>
													setLineItems(
														lineItems.filter(
															(item) => item.ident !== lineItem.ident,
														),
													)
												}
											/>
										</td>
									</tr>
									<tr>
										<td colSpan={6} className="pt-0 p-3">
											{lineItem.description}
										</td>
									</tr>
								</Fragment>
							))}
						</tbody>
					</Table>
					{!_.isNull(error) ? (
						<Alert dismissible variant="danger" onClose={() => setError(null)}>
							{error}
						</Alert>
					) : null}
					<Button
						className="bi bi-plus-square float-end"
						onClick={addLineItem}
						type="button"
						variant="outline-success"
					/>
					{settings.quoteNewMessage ? (
						<Alert variant="warning mt-auto">{settings.quoteNewMessage}</Alert>
					) : (
						''
					)}
					<Button
						variant="primary mt-auto"
						type="submit"
						disabled={savingQuote}
					>
						{savingQuote ? (
							<Spinner animation="border" role="status">
								<span className="visually-hidden">
									Saving Quote, please wait...
								</span>
							</Spinner>
						) : (
							'Send Quote'
						)}
					</Button>
				</Col>
			</Row>
		</Form>
	)
}

export default NewQuote
