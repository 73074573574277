import { Col, Container, Row } from 'react-bootstrap'
import { useImpersonation } from 'hooks/useImpersonation'
import PageTitle from './page.title.component'
import UserInfo from './user.info.component'

const Header = () => {
	const { impersonatedCustomer } = useImpersonation()

	return (
		<Container fluid>
			<div className="header">
				<Row className="float-start w-100">
					<Col>
						<PageTitle />
					</Col>
					<Col>{!impersonatedCustomer ? <UserInfo /> : null}</Col>
				</Row>
			</div>
		</Container>
	)
}

export default Header
