import { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Alert, Stack, Badge, Dropdown } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import _ from 'lodash'

const getChosenName = (chosen) => {
	if (chosen === null) return 'Select Account'
	if (chosen.attributes) return chosen.attributes.name
	return chosen.company.name
}
const CompanyChooser = ({ me, setMe, setErrorMsg }) => {
	const navigate = useNavigate()
	const { settleOnCompany, signout, currentCompany } = useAuth()
	const [chosen, setChosen] = useState(null) // chosenCustomerOrClient

	useEffect(() => {
		if (currentCompany) navigate('/home')
	}, [currentCompany, navigate])

	const settle = () => {
		settleOnCompany(chosen, { isPersisted: true })
		navigate('/home')
	}

	const startOver = (e) => {
		e.preventDefault()
		signout()
		setMe(null)
		setErrorMsg(null)
	}

	const dropdownItemStyle = {
		whiteSpace: 'normal',
		wordWrap: 'break-word',
		maxWidth: '100%',
	}

	return (
		<>
			<div className="d-grid gap-4 mb-4">
				<Alert key={1} variant="primary" className="text-start">
					<Stack direction="horizontal" gap={3}>
						<i
							className="bi bi-exclamation-circle"
							style={{ fontSize: 35 }}
						></i>
						<h6>
							Your Email is attached to multiple companies. Please select your
							company name below.
						</h6>
					</Stack>
				</Alert>
				<Dropdown>
					<Dropdown.Toggle className="w-100" variant="outline-secondary">
						{getChosenName(chosen)}
					</Dropdown.Toggle>
					<Dropdown.Menu className="w-100">
						{me.customers?.map((cmp, id) => (
							<Dropdown.Item
								onClick={() => setChosen(cmp)}
								key={id}
								style={dropdownItemStyle}
							>
								{cmp.attributes.name}
								<Badge bg="mine" style={{ float: 'right' }}>
									{cmp.company.name}
								</Badge>
							</Dropdown.Item>
						))}
						{me.clients?.map((cmp, id) => (
							<Dropdown.Item
								onClick={() => setChosen(cmp)}
								key={id}
								style={dropdownItemStyle}
							>
								{cmp.company.name}
								<Badge bg="mine" style={{ float: 'right' }}>
									Epicor Client
								</Badge>
							</Dropdown.Item>
						))}
					</Dropdown.Menu>
				</Dropdown>
				<Button
					size="lg"
					variant={chosen ? 'primary' : 'secondary'}
					disabled={_.isNull(chosen)}
					onClick={settle}
				>
					Login
				</Button>
			</div>
			<Button
				className="link-dark text-decoration-none"
				onClick={startOver}
				variant="link"
			>
				Return to Login screen
			</Button>
		</>
	)
}
export default CompanyChooser
