import { Navbar, Nav } from 'react-bootstrap'
import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import MenuOptions from './menu.options.component'
import Logo from '../shared/logo.component'

const SideBar = () => {
	const [expanded, setExpanded] = useState(false)
	const location = useLocation()

	useEffect(() => {
		setExpanded(false)
	}, [location])

	return (
		<div>
			<Navbar
				expand="md"
				className="navbar-dark flex-md-column"
				expanded={expanded}
			>
				<Navbar.Brand>
					<Logo />
				</Navbar.Brand>
				<Navbar.Toggle onClick={() => setExpanded(expanded ? false : true)} />
				<Navbar.Collapse>
					<Nav className="flex-md-column">
						<MenuOptions />
					</Nav>
				</Navbar.Collapse>
			</Navbar>
		</div>
	)
}

export default SideBar
