// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorOrderService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} customerId
	 */
	findAll(customerId) {
		return this.#http.get(`/api/customers/${customerId}/orders`)
	}

	/**
	 * @param {number} customerId
	 * @param {any} orderNum
	 */
	getLines(customerId, orderNum) {
		return this.#http.get(
			`/api/customers/${customerId}/orders/${orderNum}/lines`,
		)
	}

	/**
	 * @param {unknown} data
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {number} customerId
	 */
	create(data, environmentId, companyId, customerId) {
		return this.#http.post(`/api/customers/${customerId}/orders`, {
			data,
			environmentId,
			companyId,
		})
	}

	/**
	 * @param {number} customerId
	 * @param {any} orderNum
	 */
	print(customerId, orderNum) {
		return this.#http.get(
			`/api/customers/${customerId}/orders/${orderNum}/print`,
		)
	}
}

export function useEpicorOrderService() {
	const http = useHttp()
	return useMemo(() => new EpicorOrderService(http), [http])
}
