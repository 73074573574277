import { useState } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { useUserDataService } from '../../services/user.service'
import EditUser from './edit.user.component'
import NewUser from './new.user.component'

const UserForm = ({
	show = false,
	mode = 'create',
	onClose,
	onOperationCompleted,
	userData,
}) => {
	const [error, setError] = useState(null)

	const UserDataService = useUserDataService()

	const create = async (newUser) => {
		if (newUser.roleId.length === 0) delete newUser.roleId
		if (!UserDataService) {
			return
		}
		try {
			const response = await UserDataService.invite(newUser)
			onOperationCompleted?.(response.data.message)
			onClose?.()
		} catch (error) {
			setError(error.message)
		}
	}

	const update = async (formData) => {
		try {
			await UserDataService.update(formData.userId, formData)
			onOperationCompleted?.(`Updated ${formData.email}`)
			setError('')
			onClose?.()
		} catch (error) {
			setError(error.message)
		}
	}

	const FormContent = () => {
		switch (mode) {
			case 'create':
				return <NewUser onClose={onClose} onSubmit={create} />
			case 'edit':
				return (
					<EditUser
						onClose={onClose}
						onSubmit={update}
						userData={userData}
						adminEdit
					/>
				)
			default:
				throw new Error('Unrecognized mode.')
		}
	}

	return (
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					{mode === 'create' ? 'Add User' : 'Edit User'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error ? <Alert variant="danger">{error}</Alert> : ''}
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default UserForm
