import { formatCurrency, formatDate } from '../utilities'

export const linesAccessors = [
	{
		Header: 'Order Lines',
		columns: [
			{
				Header: 'Line',
				accessor: 'orderLine',
			},
			{
				Header: 'Part',
				accessor: 'partNum',
			},
			{
				Header: 'Description',
				accessor: 'lineDesc',
			},
			{
				Header: 'Qty',
				accessor: 'orderQty',
			},
			{
				Header: 'Request Date',
				accessor: (item) =>
					item.requestDate != null ? formatDate(item.requestDate) : '',
			},
			{
				Header: 'Price',
				accessor: (item) =>
					formatCurrency(item.docUnitPrice, item.currencyCodeCurrencyID),
			},
			{
				Header: 'Ext Price',
				accessor: (item) =>
					formatCurrency(item.docExtPriceDtl, item.currencyCodeCurrencyID),
			},
		],
	},
]
