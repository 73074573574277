import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useEpicorSharedService } from 'services/epicor'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import PaymentsTable from './payments.table.component'

const PaymentsList = ({ refresher }) => {
	const [payments, setPayments] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()
	const EpicorSharedService = useEpicorSharedService()

	const fetchPayments = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorSharedService.findAll(customerId, 'payments')
			setPayments(response.data.stripePayments)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorSharedService, customerId])

	useEffect(() => {
		if (customerId !== undefined) {
			fetchPayments()
		}
	}, [customerId, fetchPayments, refresher.id])

	return (
		<div style={containerItem}>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<PaymentsTable payments={payments} /> //TODO: Display Details on Click
			)}
		</div>
	)
}

export default PaymentsList
