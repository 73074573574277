import { Col, Dropdown, Row } from 'react-bootstrap'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import useAuth from 'hooks/useAuth'
import UserForm from '../users/user.form.component'
import { CustomMenu, CustomToggle } from '../shared/custom.menu.component'

const UserInfo = () => {
	const { user, currentCompany, signout } = useAuth()
	const [showEditUser, setShowEditUser] = useState(false)
	const navigate = useNavigate()

	const handleEditUser = () => {
		setShowEditUser(true)
	}

	return (
		<>
			<Row>
				<Col>
					<span className="circle">
						{user.firstName.charAt(0)}
						{user.lastName.charAt(0)}
					</span>
				</Col>
				<Col className="d-none d-sm-block">
					<span>
						{user.firstName} {user.lastName}
					</span>
					<span className="badge bg-orange text-white fit-content d-none d-lg-block">
						{currentCompany.company.name}
					</span>
				</Col>
				<Col>
					<Dropdown>
						<Dropdown.Toggle
							as={CustomToggle}
							id="dropdown-custom-components"
						/>
						<Dropdown.Menu as={CustomMenu}>
							<Dropdown.Item eventKey="1" onClick={handleEditUser}>
								Update User
							</Dropdown.Item>
							<Dropdown.Item
								eventKey="1"
								onClick={() => {
									navigate(`/signin/${user.accessToken}`)
								}}
							>
								Update Password
							</Dropdown.Item>
							<Dropdown.Item eventKey="1" onClick={signout}>
								Sign Out
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</Col>
			</Row>

			<UserForm
				show={showEditUser}
				mode="edit"
				onClose={() => setShowEditUser(false)}
				userData={user}
			/>
		</>
	)
}

export default UserInfo
