import { useEffect, useState } from 'react'
import { Alert, Button, Form } from 'react-bootstrap'
import { useEnvironmentDataService } from 'services/epicor'
import PasswordField from '../shared/password.field.component'

const EditConnection = ({ onSubmit, envId }) => {
	const [url, setUrl] = useState('')
	const [apiKey, setApiKey] = useState('')
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [localName, setLocalName] = useState('')
	const [baseCompany, setBaseCompany] = useState('')
	const [error, setError] = useState('')
	const [showComingSoon, setShowComingSoon] = useState(false)

	const EnvironmentDataService = useEnvironmentDataService()

	const submit = (event) => {
		event.preventDefault()
		onSubmit({
			url,
			apiKey,
			username,
			password,
			localName,
			baseCompany,
		})
	}

	useEffect(() => {
		EnvironmentDataService.findOne(envId)
			.then((response) => {
				const { data } = response
				setUrl(data.url || '')
				setApiKey(data.apiKey || '')
				setUsername(data.username || '')
				setPassword(data.password || '')
				setLocalName(data.localName || '')
				setBaseCompany(data.baseCompany || '')
			})
			.catch((error) => {
				setError(error.message)
			})
	}, [EnvironmentDataService, envId])

	return (
		<>
			{error ? <Alert variant="danger">{error}</Alert> : ''}
			<Form onSubmit={submit}>
				<div className="d-grid gap-4">
					<Form.Group controlId="form.url">
						<Form.Label>REST API Endpoint URL</Form.Label>
						<Form.Control
							type="text"
							placeholder="REST API Endpoint URL"
							value={url}
							onChange={(e) => setUrl(e.target.value)}
							autoFocus
						/>
					</Form.Group>
					<Form.Group controlId="form.apikey">
						<Form.Label>API Keys</Form.Label>
						<PasswordField
							name="apikey"
							placeholder="Enter the API Key"
							value={apiKey}
							onChange={(e) => setApiKey(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="form.username">
						<Form.Label>Username</Form.Label>
						<Form.Control
							type="text"
							placeholder="Username"
							value={username}
							onChange={(e) => setUsername(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="form.password">
						<Form.Label>Password</Form.Label>
						<PasswordField
							name="password"
							placeholder="Enter the password"
							value={password}
							onChange={(e) => setPassword(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="baseCompany">
						<Form.Label>Base Company ID</Form.Label>
						<Form.Control
							type="text"
							placeholder="EPIC03"
							value={baseCompany}
							onChange={(e) => setBaseCompany(e.target.value)}
						/>
					</Form.Group>
					<Form.Group controlId="localName">
						<Form.Label>Environment Title</Form.Label>
						<Form.Control
							type="text"
							placeholder="Environment title"
							value={localName}
							onChange={(e) => setLocalName(e.target.value)}
						/>
					</Form.Group>

					<Button variant="primary mt-3" type="submit">
						Save Changes
					</Button>
					<Button
						variant="light"
						onClick={() => setShowComingSoon(!showComingSoon)}
					>
						<p className="mb-0">Help Filling REST API Endpoint</p>
						{showComingSoon && (
							<small className="text-center">Coming soon</small>
						)}
					</Button>
				</div>
			</Form>
		</>
	)
}

export default EditConnection
