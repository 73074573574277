import { Modal } from 'react-bootstrap'
import NewQuery from './new.query.component'
import EditQuery from './edit.query.component'

const QueryForm = ({
	show = false,
	mode = 'create',
	query = [],
	onClose,
	onOperationCompleted,
}) => {
	const notifyCreated = (query) => {
		onOperationCompleted?.(`Query has been added: ${query.queryID}`)
		onClose?.()
	}
	const notifyUpdated = (query) => {
		onOperationCompleted?.(`Query has been updated: ${query.queryID}`)
		onClose?.()
	}

	const FormContent = () => {
		switch (mode) {
			case 'create':
				return <NewQuery onSubmitted={notifyCreated} />
			case 'edit':
				return <EditQuery query={query} onSubmitted={notifyUpdated} />
			default:
				throw new Error('Unrecognized mode.')
		}
	}

	return (
		<Modal show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>
					{mode === 'create' ? 'Add Query' : 'Edit Query'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default QueryForm
