import { useEffect, useState } from 'react'
import { Breadcrumb, Button, Alert } from 'react-bootstrap'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import { useRefresher } from 'hooks/useRefresher'
import { useSettings } from 'hooks/useSettings'
import { useTicketService } from 'services/ticket.service'
import TicketForm from './ticket.form.component'
import TicketTable from './ticket.table.component'

const HeaderComponent = ({ onCreateNewTicket }) => {
	const { settings } = useSettings()
	return (
		<div className="header">
			<Breadcrumb className="d-inline-block" id="header-breadcrumbs">
				<Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
				<Breadcrumb.Item active>Tickets</Breadcrumb.Item>
			</Breadcrumb>
			{!settings.ticketDisableCreate && (
				<Button
					style={{ float: 'right' }}
					onClick={() => {
						onCreateNewTicket()
					}}
				>
					Create New Support Ticket
				</Button>
			)}
		</div>
	)
}

const Tickets = () => {
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const [showForm, setShowForm] = useState(false)
	const [tickets, setTickets] = useState(null)
	const refresher = useRefresher()
	const TicketService = useTicketService()

	useEffect(() => {
		setLoading(true)
		TicketService.findAll()
			.then((response) => {
				setTickets(response.data)
			})
			.catch((error) => {
				setError(error)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [TicketService])

	return (
		<>
			{error ? (
				<Alert dismissible variant="danger" onClose={() => setError(null)}>
					{error.toString()}
				</Alert>
			) : null}
			<HeaderComponent
				onCreateNewTicket={() => {
					setShowForm(true)
				}}
			/>
			{loading || !tickets ? (
				<LoadingParagraphs cant={5} animation="glow" />
			) : (
				<TicketTable tickets={tickets} />
			)}
			<TicketForm
				show={showForm}
				onClose={() => setShowForm(false)}
				onOperationCompleted={refresher.refresh}
			/>
		</>
	)
}
export default Tickets
