// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorCustomerService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} customerId
	 */
	customerInfo(customerId) {
		return this.#http.get(`/api/customers/${customerId}`)
	}

	/**
	 * @param {number} customerId
	 */
	customerContacts(customerId) {
		return this.#http.get(`/api/customers/${customerId}/contacts`)
	}
}

export function useEpicorCustomerService() {
	const http = useHttp()
	return useMemo(() => new EpicorCustomerService(http), [http])
}
