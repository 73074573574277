import { useState } from 'react'
import { Alert, Modal } from 'react-bootstrap'
import { useEnvironmentDataService } from 'services/epicor'
import EditConnection from './edit.connection.component'
import NewConnection from './new.connection.component'
import VerifyConnection from './verify.connection.component'

const ConnectionForm = ({
	show = false,
	mode = 'create',
	onClose,
	onOperationCompleted,
	envId,
}) => {
	const [error, setError] = useState(null)

	const EnvironmentDataService = useEnvironmentDataService()

	const closeModal = () => {
		setError(null)
		onClose?.()
	}

	const create = async (data) => {
		try {
			const environment = await EnvironmentDataService.create(data)
			await verifyConnection(environment.data.id)
			onOperationCompleted?.('A new connection has been added')
			closeModal()
		} catch (error) {
			setError(error.message)
		}
	}

	const update = async (data) => {
		try {
			await EnvironmentDataService.update(envId, data)
			onOperationCompleted?.('All the changes were saved to the connection')
			onClose?.()
		} catch (error) {
			setError(error.message)
		}
	}

	const verifyConnection = async (environmentId = envId) => {
		try {
			await EnvironmentDataService.connect(environmentId)
			onOperationCompleted?.()
		} catch (error) {
			setError(error.message)
		}
	}

	const FormContent = () => {
		switch (mode) {
			case 'create':
				return <NewConnection onSubmit={create} />
			case 'edit':
				return <EditConnection onSubmit={update} envId={envId} />
			case 'connect':
				return (
					<VerifyConnection
						onClose={closeModal}
						onVerify={verifyConnection}
						onError={setError}
						envId={envId}
					/>
				)
			default:
				throw new Error('Unrecognized mode.')
		}
	}

	return (
		<Modal show={show} onHide={closeModal}>
			<Modal.Header closeButton>
				<Modal.Title>REST API endpoint</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				{error ? <Alert variant="danger">{error}</Alert> : ''}
				<FormContent />
			</Modal.Body>
		</Modal>
	)
}

export default ConnectionForm
