// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorQuoteService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} customerId
	 */
	findAll(customerId) {
		return this.#http.get(`/api/customers/${customerId}/quotes`)
	}

	/**
	 * @param {number} customerId
	 * @param {string} quoteNum
	 */
	getLines(customerId, quoteNum) {
		return this.#http.get(
			`/api/customers/${customerId}/quotes/${quoteNum}/lines`,
		)
	}

	/**
	 * @param {unknown} data
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {number} customerId
	 */
	create(data, environmentId, companyId, customerId) {
		return this.#http.post(`/api/customers/${customerId}/quotes`, {
			data,
			environmentId,
			companyId,
		})
	}

	/**
	 * @param {number} customerId
	 * @param {string} quoteNum
	 */
	print(customerId, quoteNum) {
		return this.#http.get(
			`/api/customers/${customerId}/quotes/${quoteNum}/print`,
			{
				responseType: 'blob',
			},
		)
	}
}

export function useEpicorQuoteService() {
	const http = useHttp()
	return useMemo(() => new EpicorQuoteService(http), [http])
}
