import { formatCurrency, formatDate } from 'components/utilities'

export const accessors = () => [
	{
		Header: 'Payments List',
		columns: [
			{
				Header: 'Payment Date',
				accessor: (item) => formatDate(item.createdAt),
			},
			{
				Header: 'Invoices',
				accessor: (item) => {
					const sortedIds = item.invoiceIds.split(',').sort()
					return (
						<div>
							{sortedIds.map((id, index) => (
								<div key={index}>{id.trim()}</div>
							))}
						</div>
					)
				},
			},
			{
				Header: 'Payment Amount',
				accessor: (item) => formatCurrency(item.total, item.currency),
			},
		],
	},
]
