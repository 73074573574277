import { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Form, InputGroup, Container, Alert } from 'react-bootstrap'
import CompanyChooser from './company-chooser.component'
import useAuth from 'hooks/useAuth'
import { centeredSquareForm } from '../shared/styles'
import { checkPasswordComplexity } from '@elevate/shared/validation'

const NewPassword = () => {
	const { key } = useParams()
	const [errorMsg, setErrorMsg] = useState(null)
	const [passwordError, setPasswordError] = useState(null)
	const [passwordFieldType, setPasswordFieldType] = useState(true)
	const [disableBtn, setDisableLoginButton] = useState(true)
	const [success, setSuccess] = useState(false)
	const [email, setEmail] = useState('')
	const [user, setUser] = useState([])
	const { settleOnCompany, updatePassword } = useAuth()
	const navigate = useNavigate()

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_BASE_URL}/api/auth/reset`, {
			headers: {
				'x-access-token': key,
			},
		})
			.then((res) => {
				if (!res.ok) {
					setErrorMsg('Your reset password failed to be authenticated.')
					return null
				}
				return res.json()
			})
			.then((data) => {
				if (data) {
					setErrorMsg(null)
					setEmail(data.email)
				}
			})
			.catch((error) => {
				console.error('Error:', error)
				setErrorMsg('An error occurred while processing your request.')
			})
	}, [key])
	const sendPassword = async (event) => {
		event.preventDefault()
		const newPassword = event.target.password?.value
		const complexityCheck = checkPasswordComplexity(newPassword)

		if (!complexityCheck.isValid) {
			setPasswordError(complexityCheck.message)
			return
		}
		setPasswordError(null)
		try {
			const user = await updatePassword(key, email, newPassword)
			// expects new token and user with customers and clients
			setSuccess(true)
			if (user.customerAndClientCount > 0) {
				if (user.customerAndClientCount === 1) {
					settleOnCompany(
						user.customers.length > 0 ? user.customers[0] : user.clients[0],
					)
					navigate('/home')
				} else {
					setUser(user)
				}
			}
			setErrorMsg('')
		} catch (err) {
			// TODO Axios should get better error handling.
			setErrorMsg('Your new password failed to update.')
		}
	}

	function checkFieldsFilled(e) {
		setDisableLoginButton(!e.currentTarget.form.checkValidity())
	}

	return !success ? (
		<Container>
			<Form
				style={centeredSquareForm}
				autoComplete="on"
				onSubmit={sendPassword}
				className="needs-validation"
				noValidate
			>
				<h2>Update Password</h2>
				{errorMsg ? (
					<Alert variant="danger">{errorMsg}</Alert>
				) : (
					<>
						<Alert variant="primary" style={{ textAlign: 'left' }}>
							Password must contain at least:
							<ul>
								<li>8 Characters</li>
								<li>1 Uppercase Letter</li>
								<li>1 Lowercase Letter</li>
								<li>1 Number</li>
								<li>1 Special Character</li>
								<li>Not be too weak</li>
							</ul>
						</Alert>
						{passwordError && <Alert variant="danger">{passwordError}</Alert>}
						<div className="d-grid gap-4">
							<InputGroup>
								<InputGroup.Text>{email}</InputGroup.Text>
							</InputGroup>
							<InputGroup>
								<Form.Control
									name="password"
									required
									size="lg"
									type={passwordFieldType ? 'password' : 'texts'}
									placeholder="Password"
									onChange={checkFieldsFilled}
								/>
								<InputGroup.Text
									onClick={() => setPasswordFieldType(!passwordFieldType)}
								>
									show
								</InputGroup.Text>
								<Form.Control.Feedback type="invalid">
									Please enter password
								</Form.Control.Feedback>
							</InputGroup>
							<InputGroup>
								<Form.Control
									name="password1"
									required
									size="lg"
									type={passwordFieldType ? 'password' : 'texts'}
									placeholder="Verify Password"
									onChange={checkFieldsFilled}
								/>
								<InputGroup.Text
									onClick={() => setPasswordFieldType(!passwordFieldType)}
								>
									show
								</InputGroup.Text>
								<Form.Control.Feedback type="invalid">
									Please enter password
								</Form.Control.Feedback>
							</InputGroup>
							<Button
								size="lg"
								variant={disableBtn ? 'secondary' : 'primary'}
								type="submit"
								disabled={disableBtn}
							>
								Save
							</Button>
						</div>
					</>
				)}
			</Form>
		</Container>
	) : (
		<Container style={centeredSquareForm}>
			<i
				className="bi bi-check-circle"
				style={{ fontSize: '5rem', color: '#11BD22' }}
			></i>
			<p>Password has been changed successfully.</p>
			{user.customerAndClientCount > 1 ? (
				<CompanyChooser {...{ me: user, setMe: setUser, setErrorMsg }} />
			) : (
				<a href="/" onClick={() => navigate('/')}>
					Home
				</a>
			)}
		</Container>
	)
}

export default NewPassword
