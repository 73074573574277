import { formatCurrency } from 'components/utilities'

export const linesAccessors = [
	{
		Header: 'Quote Lines',
		columns: [
			{
				Header: 'Line',
				accessor: 'quoteLine',
			},
			{
				Header: 'Part',
				accessor: 'partNum',
			},
			{
				Header: 'Description',
				accessor: 'lineDesc',
			},
			{
				Header: 'Qty',
				accessor: 'quoteQty',
			},
			{
				Header: 'Price',
				accessor: (item) =>
					formatCurrency(item.docUnitPrice, item.currencyCurrencyID),
			},
			{
				Header: 'Ext Price',
				accessor: (item) =>
					formatCurrency(item.docExtPriceDtl, item.currencyCurrencyID),
			},
		],
	},
]
