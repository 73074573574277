import { useMemo } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { accessors } from './helpdesk.table.accessors.component'

import TableHead from '../shared/table.header.component'
import _ from 'lodash'

const HelpDesksTable = ({ cases }) => {
	const columns = useMemo(() => accessors(), [])
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data: cases },
		useSortBy,
	)

	const TableBody = () => {
		return (
			<tbody>
				{rows.map((row) => {
					prepareRow(row)
					const { key: rowKey, ...rowProps } = row.getRowProps()

					return (
						<tr key={rowKey} {...rowProps}>
							{row.cells.map((cell) => {
								const { key: cellKey, ...cellProps } = cell.getCellProps()
								return (
									<td key={cellKey} {...cellProps}>
										{cell.render('Cell')}
									</td>
								)
							})}
						</tr>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(cases) ? (
		<BTable responsive hover {...getTableProps()}>
			<TableHead headerGroups={headerGroups} />
			<TableBody />
		</BTable>
	) : (
		<p>The current customer does not have any cases yet. Let's create one!</p>
	)
}

export default HelpDesksTable
