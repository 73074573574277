import { useEffect, useState } from 'react'
import { Alert, Button, Col, Form } from 'react-bootstrap'
import { useEnvironmentDataService } from 'services/epicor'
import _ from 'lodash'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'

const VerifyConnection = ({ onClose, onVerify, onError, envId }) => {
	const [loading, setLoading] = useState(false)
	const [companiesList, setCompaniesList] = useState([])
	const [numberOfLicenses, setNumberOfLicenses] = useState(null)
	const [version, setVersion] = useState(null)
	const verified = !_.isEmpty(companiesList) && numberOfLicenses

	const EnvironmentDataService = useEnvironmentDataService()

	const VerifiedNotice = () => {
		return verified ? (
			<Alert variant="success">
				<i className="bi bi-check-circle"></i> You have successfully connected
				and verified your REST API endpoint
			</Alert>
		) : null
	}

	const ListOfCompanies = () => {
		return !_.isEmpty(companiesList) ? (
			<>
				<h6>Companies</h6>
				<ul>
					{companiesList.map((company, i) => {
						return (
							<li key={i}>
								({company.company1}) {company.name}
							</li>
						)
					})}
				</ul>
			</>
		) : null
	}

	const NumberOfLicenses = () => {
		return numberOfLicenses ? (
			<>
				<Col>
					<span className="badge text-white bg-orange">
						{numberOfLicenses} Licenses
					</span>
				</Col>
			</>
		) : null
	}

	const Version = () => {
		return version ? (
			<>
				<Col>
					<span className="badge text-white bg-orange">{version}</span>
				</Col>
			</>
		) : null
	}

	const VerifiedInfo = () => {
		return (
			<>
				<VerifiedNotice />
				<ListOfCompanies />
				<NumberOfLicenses />
				<Version />
			</>
		)
	}

	const updateData = (data) => {
		setCompaniesList(JSON.parse(data.companiesList))
		setNumberOfLicenses(data.numberOfLicenses)
		setVersion(data.version)
	}

	const verify = async (event) => {
		event.preventDefault()
		setLoading(true)
		try {
			await onVerify()
		} finally {
			setLoading(false)
		}
	}

	useEffect(() => {
		EnvironmentDataService.findOne(envId)
			.then((response) => updateData(response.data))
			.catch((error) => onError(error.message))
	}, [EnvironmentDataService, envId, onError])

	return (
		<Form onSubmit={verify}>
			<div className="d-grid gap-4">
				{loading ? <LoadingParagraphs cant={10} /> : <VerifiedInfo />}

				<Button disabled={loading} variant="primary mt-3" type="submit">
					{verified ? 'Reconnect' : 'Connect'}
				</Button>
				<Button
					disabled={loading}
					variant="light"
					onClick={() => {
						onClose?.()
					}}
				>
					Cancel
				</Button>
			</div>
		</Form>
	)
}

export default VerifyConnection
