import { useEffect, useState, useCallback } from 'react'
import {
	Alert,
	Button,
	Col,
	Form,
	Row,
	Spinner,
	Placeholder,
} from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import { useEpicorQueryService } from 'services/epicor'
import _ from 'lodash'

function usePermissions() {
	const [allowUpdate, setAllowUpdate] = useState(false)
	const [allowAddNew, setAllowAddNew] = useState(false)
	const setPermissions = useCallback(({ allowUpdate, allowAddNew }) => {
		setAllowUpdate(allowUpdate)
		setAllowAddNew(allowUpdate ? allowAddNew : false)
	}, [])

	return {
		allowUpdate,
		allowAddNew,
		setPermissions,
	}
}

const EditQuery = ({ query, onSubmitted }) => {
	const { currentCompany } = useAuth()
	const EpicorQueryService = useEpicorQueryService()
	const [error, setError] = useState(null)
	const [loading, setLoading] = useState(true)
	const [queryCustNumField, setQueryCustNumField] = useState('')
	const [queryName, setQueryName] = useState('')
	const { allowUpdate, allowAddNew, setPermissions } = usePermissions()
	const [queryFields, setQueryFields] = useState([])
	const [savingQuery, setSavingQuery] = useState(false)
	const payload = { queryName, queryCustNumField, allowUpdate, allowAddNew }

	useEffect(() => {
		setQueryCustNumField(query.queryCustNumField)
		setQueryName(query.queryName)
		setPermissions({
			allowUpdate: query.allowUpdate,
			allowAddNew: query.allowAddNew,
		})
	}, [query, setPermissions])

	useEffect(() => {
		setLoading(true)
		EpicorQueryService.fieldsList(
			currentCompany.company.environmentId,
			currentCompany.company.id,
			query.queryID,
		)
			.then((response) => {
				setQueryFields(response.data)
			})
			.catch((error) => {
				setError(error instanceof Error ? error.message : String(error))
			})
			.finally(() => {
				setLoading(false)
			})
	}, [EpicorQueryService, query, currentCompany])

	const submit = async (event) => {
		event.preventDefault()
		setSavingQuery(true)
		try {
			const response = await EpicorQueryService.update(
				currentCompany.company.environmentId,
				currentCompany.company.id,
				query.id,
				payload,
			)
			const { error } = response.data
			if (error) {
				setError(JSON.stringify(error))
			} else {
				onSubmitted(query)
			}
		} catch (error) {
			setError(error.message)
		} finally {
			setSavingQuery(false)
		}
	}

	const QueryFieldOptions = () => {
		return queryFields.map((queryField) => (
			<option key={queryField.alias} value={queryField.alias}>
				{queryField.alias}
			</option>
		))
	}

	const requiredFieldsCompleted =
		!_.isNull(query.queryId) && !_.isNull(queryCustNumField)

	return (
		<Form onSubmit={submit}>
			<Row>
				<Col className="d-flex flex-column">
					<h4 className="text-grey">{query.queryID}</h4>
					{!_.isNull(error) ? (
						<Alert dismissible variant="danger" onClose={() => setError(null)}>
							{error}
						</Alert>
					) : null}
					{loading ? (
						<>
							<Placeholder animation="glow">
								<Placeholder xs={6} size="lg" />
							</Placeholder>
							<Placeholder animation="glow">
								<Placeholder xs={6} size="lg" />
							</Placeholder>
							<Placeholder animation="glow">
								<Placeholder xs={4} size="xs" />
								<br />
								<Placeholder xs={4} size="xs" />
							</Placeholder>
							<Placeholder.Button variant="primary" />
						</>
					) : (
						<>
							<Form.Group controlId="form.queryCustNumField">
								<Form.Label>CustNum Field</Form.Label>
								<Form.Select
									name="queryCustNumField"
									value={queryCustNumField}
									onChange={(e) => setQueryCustNumField(e.target.value)}
								>
									<QueryFieldOptions />
								</Form.Select>
							</Form.Group>

							<Form.Group controlId="form.queryName">
								<Form.Label>Description (optional)</Form.Label>
								<Form.Control
									name="queryName"
									placeholder="Give the Query a Customer-Friendly Description"
									value={queryName}
									onChange={(e) => setQueryName(e.target.value)}
								/>
							</Form.Group>
							<Form.Group controlId="form.allowUpdate">
								<Form.Check
									name="allowUpdate"
									label="Updateable"
									checked={allowUpdate}
									onChange={(event) =>
										setPermissions({
											allowUpdate: event.target.checked,
											allowAddNew,
										})
									}
								/>
							</Form.Group>
							<Form.Group controlId="form.allowAddNew">
								<Form.Check
									name="allowAddNew"
									label="Allow Add New"
									checked={allowAddNew}
									onChange={(event) =>
										setPermissions({
											allowUpdate,
											allowAddNew: event.target.checked,
										})
									}
									disabled={!allowUpdate}
								/>
							</Form.Group>
							<p />
							<Button
								variant="primary mt-auto"
								type="submit"
								disabled={!requiredFieldsCompleted || savingQuery}
							>
								{savingQuery ? (
									<Spinner animation="border" role="status">
										<span className="visually-hidden">
											Saving Query, please wait...
										</span>
									</Spinner>
								) : (
									'Save Query'
								)}
							</Button>
						</>
					)}
					{!requiredFieldsCompleted ? (
						<small className="text-center text-grey">
							Please, make sure to complete all the required elements
						</small>
					) : null}
				</Col>
			</Row>
		</Form>
	)
}

export default EditQuery
