import { Modal } from 'react-bootstrap'
import NewQuote from './new-quote/new.quote.component'

const QuoteForm = ({ show = false, onClose, onOperationCompleted }) => {
	const notifyCreated = (data) => {
		onOperationCompleted?.(
			`A new Quote has been added. Quote number: ${data.quoteNumber}`,
		)
		onClose?.()
	}

	return (
		<Modal size={'xl'} show={show} onHide={onClose}>
			<Modal.Header closeButton>
				<Modal.Title>Create Quote</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<NewQuote onSubmitted={notifyCreated} />
			</Modal.Body>
		</Modal>
	)
}

export default QuoteForm
