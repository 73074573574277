import useAuth from 'hooks/useAuth'
import ConnectionCard from './connection.card.component'
import { useCallback, useEffect, useState } from 'react'
import LoadingCards from '../shared/loading/loading.card.component'
import { useEnvironmentDataService } from 'services/epicor'
import { Alert } from 'react-bootstrap'
import _ from 'lodash'

const ConnectionsList = ({ refresher, onCardClick, onVerify }) => {
	const { currentCompany } = useAuth()
	const [environments, setEnvironments] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const hasConnections = !_.isEmpty(environments)

	const EnvironmentDataService = useEnvironmentDataService()

	const remove = async (id) => {
		try {
			await EnvironmentDataService.remove(id)
			await fetchEnvironments()
		} catch (error) {
			setError(error.message)
		}
	}

	const setAsLive = async (id, isLive) => {
		try {
			await EnvironmentDataService.update(id, { isLive: isLive })
			await fetchEnvironments()
		} catch (error) {
			setError(error.message)
		}
	}

	const fetchEnvironments = useCallback(async () => {
		try {
			const response = await EnvironmentDataService.findAll(
				currentCompany.company.id,
			)
			setEnvironments([response.data])
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EnvironmentDataService, currentCompany.company.id])

	useEffect(() => {
		fetchEnvironments()
	}, [fetchEnvironments, refresher.id])

	return (
		<>
			{error ? <Alert variant="danger">{error}</Alert> : ''}
			{loading ? (
				<LoadingCards />
			) : hasConnections ? (
				environments.map((c) => (
					<ConnectionCard
						key={c.id}
						environment={c}
						onRemove={remove}
						onSetAsLive={setAsLive}
						onClick={() => onCardClick(c.id)}
						onVerify={onVerify}
					/>
				))
			) : (
				<p>You don't have any connections yet.</p>
			)}
		</>
	)
}

export default ConnectionsList
