// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorShipmentService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} customerId
	 */
	findAll(customerId) {
		return this.#http.get(`/api/customers/${customerId}/shipments`)
	}

	/**
	 * @param {number} customerId
	 * @param {string} packNum
	 */
	getLines(customerId, packNum) {
		return this.#http.get(
			`/api/customers/${customerId}/shipments/${packNum}/lines`,
		)
	}

	/**
	 * @param {number} customerId
	 * @param {string} packNum
	 */
	print(customerId, packNum) {
		return this.#http.get(
			`/api/customers/${customerId}/shipments/${packNum}/print`,
		)
	}

	/**
	 * @param {number} customerId
	 * @param {string} packNum
	 * @param {string} bolNum
	 */
	printBOL(customerId, packNum, bolNum) {
		return this.#http.get(
			`/api/customers/${customerId}/shipments/${packNum}/printBOL/${bolNum}`,
		)
	}
}

export function useEpicorShipmentService() {
	const http = useHttp()
	return useMemo(() => new EpicorShipmentService(http), [http])
}
