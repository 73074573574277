// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorQueryService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} environmentId
	 * @param {number} companyId
	 */
	findAll(environmentId, companyId) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/queries`,
		)
	}

	/**
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {number} queryId
	 */
	findOne(environmentId, companyId, queryId) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/queries/${queryId}`,
		)
	}

	/**
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {string} epicorQueryId
	 */
	fieldsList(environmentId, companyId, epicorQueryId) {
		return this.#http.get(
			`/api/environments/${environmentId}/company/${companyId}/queries/fields?epicorQueryId=${epicorQueryId}`,
		)
	}

	/**
	 * @param {number} customerId
	 * @param {number} queryId
	 */
	fieldsListCustomer(customerId, queryId) {
		return this.#http.get(
			`/api/customers/${customerId}/queries/${queryId}/fields`,
		)
	}

	/**
	 * @param {number} customerId
	 * @param {number} queryId
	 * @param {string} outputType
	 */
	execute(customerId, queryId, outputType) {
		let url = `/api/customers/${customerId}/queries/${queryId}`
		if (outputType) {
			url += `?outputType=${outputType}`
		}
		return this.#http.get(url)
	}

	/**
	 * @param {number} customerId
	 * @param {number} queryId
	 * @param {unknown[]} data
	 */
	updateRecords(customerId, queryId, data) {
		return this.#http.post(
			`/api/customers/${customerId}/queries/${queryId}`,
			data,
		)
	}

	/**
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {unknown} data
	 */
	create(environmentId, companyId, data) {
		return this.#http.post(
			`/api/environments/${environmentId}/company/${companyId}/queries`,
			data,
		)
	}

	/**
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {number} queryId
	 * @param {unknown} data
	 */
	update(environmentId, companyId, queryId, data) {
		return this.#http.put(
			`/api/environments/${environmentId}/company/${companyId}/queries/${queryId}`,
			data,
		)
	}

	/**
	 * @param {number} environmentId
	 * @param {number} companyId
	 * @param {number} queryId
	 */
	deleteById(environmentId, companyId, queryId) {
		return this.#http.delete(
			`/api/environments/${environmentId}/company/${companyId}/queries/${queryId}`,
		)
	}
}

export function useEpicorQueryService() {
	const http = useHttp()
	return useMemo(() => new EpicorQueryService(http), [http])
}
