// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'
import { useSession } from 'hooks/useSession'

class EpicorWidgetService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 * @param {CustomerOrClient} currentCompany
	 */
	constructor(http, currentCompany) {
		this.#http = http
		this.#currentCompany = currentCompany
	}

	#http
	#currentCompany

	/**
	 * @param {number} customerId
	 * @param {string} widgetName
	 */
	getWidget(customerId, widgetName) {
		if (customerId === 0) {
			const { environmentId } = this.#currentCompany.company
			const { companyId } = this.#currentCompany
			return this.#http.get(
				`/api/environments/${environmentId}/company/${companyId}/widgets/${widgetName}`,
			)
		} else {
			return this.#http.get(
				`/api/customers/${customerId}/widgets/${widgetName}`,
			)
		}
	}
}

export function useEpicorWidgetService() {
	const http = useHttp()
	const { currentCompany } = useSession()
	if (!currentCompany) {
		throw new Error(
			'Widget service must have currentCompany loaded in the session.',
		)
	}
	return useMemo(
		() => new EpicorWidgetService(http, currentCompany),
		[currentCompany, http],
	)
}
