import { formatCurrency } from 'components/utilities'

export const linesAccessors = [
	{
		Header: 'Invoice Lines',
		columns: [
			{
				Header: 'Line',
				accessor: 'invoiceLine',
			},
			{
				Header: 'Part',
				accessor: 'partNum',
			},
			{
				Header: 'Description',
				accessor: 'lineDesc',
			},
			{
				Header: 'Qty',
				accessor: 'ourShipQty',
			},
			{
				Header: 'Unit Price',
				accessor: (item) =>
					formatCurrency(item.unitPrice, item.currencyCodeCurrencyID),
			},
			{
				Header: 'Ext Price',
				accessor: (item) =>
					formatCurrency(item.extPrice, item.currencyCodeCurrencyID),
			},
			{
				Header: 'Discount Amount',
				accessor: (item) =>
					formatCurrency(item.discount, item.currencyCodeCurrencyID),
			},
		],
	},
]
