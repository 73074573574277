import { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { Alert } from 'react-bootstrap'
import {
	useEpicorInvoiceService,
	useEpicorOrderService,
	useEpicorQuoteService,
	useEpicorRMAService,
	useEpicorShipmentService,
} from 'services/epicor'
import { containerItem } from './styles'
import LoadingParagraphs from './loading/loading.paragraph.component'
import LinesTable from './lines.table.component'

const RecordLines = ({ record, type, linesAccessors }) => {
	const [recordLines, setRecordLines] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const EpicorOrderService = useEpicorOrderService()
	const EpicorQuoteService = useEpicorQuoteService()
	const EpicorRMAService = useEpicorRMAService()
	const EpicorInvoiceService = useEpicorInvoiceService()
	const EpicorShipmentService = useEpicorShipmentService()

	const service = useMemo(() => {
		switch (type) {
			case 'order':
				return EpicorOrderService
			case 'quote':
				return EpicorQuoteService
			case 'rma':
				return EpicorRMAService
			case 'invoice':
				return EpicorInvoiceService
			case 'shipment':
				return EpicorShipmentService
			default:
				throw new Error(`Unexpected service type: ${type}`)
		}
	}, [
		EpicorInvoiceService,
		EpicorOrderService,
		EpicorQuoteService,
		EpicorRMAService,
		EpicorShipmentService,
		type,
	])

	const fetchRecordLines = useCallback(async () => {
		setLoading(true)
		try {
			const response = await service.getLines(customerId, record)
			setRecordLines(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [customerId, record, service])

	useEffect(() => {
		if (customerId !== undefined) {
			fetchRecordLines()
		}
	}, [customerId, fetchRecordLines])

	return (
		<div style={{ ...containerItem, backgroundColor: 'var(--bs-table-bg)' }}>
			{error ? <Alert variant="danger">{error}</Alert> : null}
			{loading ? (
				<LoadingParagraphs cant={3} />
			) : (
				<LinesTable recordLines={recordLines} linesAccessors={linesAccessors} />
			)}
		</div>
	)
}

export default RecordLines
