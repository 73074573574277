// @ts-check

import { useMemo } from 'react'
import { useHttp } from 'hooks/useHttp'

class EpicorRMAService {
	/**
	 * @param {import('axios').AxiosInstance} http
	 */
	constructor(http) {
		this.#http = http
	}

	#http

	/**
	 * @param {number} customerId
	 */
	findAll(customerId) {
		return this.#http.get(`/api/customers/${customerId}/rmas`)
	}

	/**
	 * @param {number} customerId
	 * @param {string} rmaNum
	 */
	getLines(customerId, rmaNum) {
		return this.#http.get(`/api/customers/${customerId}/rmas/${rmaNum}/lines`)
	}

	/**
	 * @param {number} customerId
	 * @param {string} rmaNum
	 */
	print(customerId, rmaNum) {
		return this.#http.get(`/api/customers/${customerId}/rmas/${rmaNum}/print`)
	}
}

export function useEpicorRMAService() {
	const http = useHttp()
	return useMemo(() => new EpicorRMAService(http), [http])
}
