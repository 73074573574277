import { useMemo, useState, Fragment } from 'react'
import { useSortBy, useTable } from 'react-table'
import { Table as BTable } from 'react-bootstrap'
import { getColumnAlignment } from 'components/utilities'
import { accessors } from './quote.table.accessors.component'
import TableHead from '../shared/table.header.component'
import RecordLines from '../shared/lines.list.component'
import { linesAccessors } from './lines.table.accessors.component'
import _ from 'lodash'

const QuotesTable = ({ quotes, onPrintQuote }) => {
	const [expandedRows, setExpandedRows] = useState({})
	const columns = useMemo(() => accessors(), [])
	const { getTableProps, headerGroups, rows, prepareRow } = useTable(
		{ columns, data: quotes, onPrintQuote },
		useSortBy,
	)

	const TableBody = () => {
		const toggleCollapse = (quoteNum, cell) => () => {
			if (cell.column.id === '⚡') return
			setExpandedRows((prevState) => {
				const nextState = {}
				Object.keys(prevState).forEach((key) => {
					nextState[key] = false
				})
				nextState[quoteNum] = !prevState[quoteNum]
				return nextState
			})
		}

		return (
			<tbody>
				{rows.map((row) => {
					prepareRow(row)
					const { key: rowKey, ...rowProps } = row.getRowProps()
					const isExpanded = expandedRows[row.original.quoteNum]

					return (
						<Fragment key={rowKey}>
							<tr
								className={isExpanded ? 'table-active' : undefined}
								{...rowProps}
							>
								{row.cells.map((cell) => {
									const { key: cellKey, ...cellProps } = cell.getCellProps()
									return (
										<td
											key={cellKey}
											{...cellProps}
											onClick={toggleCollapse(row.original.quoteNum, cell)}
											className={getColumnAlignment(cell.column.id)}
										>
											{cell.render('Cell')}
										</td>
									)
								})}
							</tr>
							{isExpanded && (
								<tr className="table-active">
									<td colSpan={row.cells.length}>
										<RecordLines
											record={row.original.quoteNum}
											type="quote"
											linesAccessors={linesAccessors}
										/>
									</td>
								</tr>
							)}
						</Fragment>
					)
				})}
			</tbody>
		)
	}

	return !_.isEmpty(quotes) ? (
		<BTable responsive hover {...getTableProps()}>
			<TableHead headerGroups={headerGroups} />
			<TableBody />
		</BTable>
	) : (
		<p>The current customer does not have any quotes yet. Let's create one!</p>
	)
}

export default QuotesTable
