import { useEffect, useState } from 'react'
import { Alert, Button, Container, Modal, Row } from 'react-bootstrap'
import { useUserDataService } from 'services/user.service'
import { useCustomerService } from 'services/customer.service'
import useAuth from 'hooks/useAuth'
import Select from 'react-select'

const ContactForm = ({
	show = false,
	mode = 'import',
	onClose,
	onOperationCompleted,
}) => {
	const [error, setError] = useState(null)
	const [customers, setCustomers] = useState([])
	const [selectedCustomerId, setSelectedCustomerId] = useState(null)
	const [isConfirmingAllContactsAction, setIsConfirmingAllContactsAction] =
		useState(false)
	const { currentCompany } = useAuth()

	const UserDataService = useUserDataService()
	const CustomerService = useCustomerService()

	useEffect(() => {
		if (show) {
			CustomerService.findAll().then((response) => {
				setCustomers(response.data)
			})
		}
	}, [CustomerService, show])

	const customerOptions = customers.map((c) => ({
		value: c.id,
		label: `${c.name} (${c.custId})`,
	}))

	const importContacts = async (customerId) => {
		try {
			const response = await UserDataService.importContacts(
				currentCompany.company.id,
				customerId,
			)
			onOperationCompleted?.(
				response.data.map((item) => item.message).join('\n'),
			)
			close()
		} catch (error) {
			setError(error.message)
		}
	}

	const activateContacts = async (customerId) => {
		try {
			const response = await UserDataService.activateContacts(
				currentCompany.company.id,
				customerId,
			)
			onOperationCompleted?.(response.data.message)
			close()
		} catch (error) {
			setError(error.message)
		}
	}

	const close = () => {
		setError(null)
		setSelectedCustomerId(null)
		onClose?.()
	}

	const confirmAllContactsAction = () => {
		setIsConfirmingAllContactsAction(true)
	}

	const runAllContactsAction = () => {
		setIsConfirmingAllContactsAction(false)
		mode === 'import' ? importContacts() : activateContacts()
	}

	const cancelAllContactsAction = () => {
		setIsConfirmingAllContactsAction(false)
	}

	const modeLabel = mode === 'import' ? 'Import' : 'Activate'

	return (
		<>
			<Modal show={show} onHide={close}>
				<Modal.Header closeButton>
					<Modal.Title>{modeLabel} Contacts</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Container>
						<Row>
							{error ? <Alert variant="danger">{error}</Alert> : ''}

							<Select
								options={customerOptions}
								onChange={(selectedOption) =>
									setSelectedCustomerId(selectedOption.value)
								}
								isSearchable
							/>
						</Row>
						<Row>
							<Button
								variant="success"
								className="mt-2 me-2"
								disabled={!selectedCustomerId}
								onClick={() =>
									mode === 'import'
										? importContacts(selectedCustomerId)
										: activateContacts(selectedCustomerId)
								}
							>
								{modeLabel} Customer Contacts
							</Button>
							<Button
								variant="warning"
								className="mt-2 me-2"
								onClick={confirmAllContactsAction}
							>
								{modeLabel} <strong>All</strong> Contacts
							</Button>
						</Row>
					</Container>
				</Modal.Body>
			</Modal>

			<Modal
				show={isConfirmingAllContactsAction}
				onHide={cancelAllContactsAction}
			>
				<Modal.Header closeButton>
					<Modal.Title>Confirm Action</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Are you sure you want to {modeLabel.toLowerCase()}{' '}
					<strong>all</strong> contacts for <strong>all</strong> customers?
					(This can take some time.)
				</Modal.Body>
				<Modal.Footer>
					<Button variant="warning" onClick={runAllContactsAction}>
						Yes
					</Button>
					<Button variant="secondary" onClick={cancelAllContactsAction}>
						No
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	)
}

export default ContactForm
