import useAuth, { useCustomerId } from 'hooks/useAuth'
import { useCallback, useEffect, useState } from 'react'
import { Alert } from 'react-bootstrap'
import { useEpicorRMAService } from 'services/epicor'
import { containerItem } from '../shared/styles'
import LoadingParagraphs from '../shared/loading/loading.paragraph.component'
import RMAsTable from './rma.table.component'

const RMAsList = () => {
	const { currentCompany } = useAuth()
	const EpicorRMAService = useEpicorRMAService()
	const [rmas, setRMAs] = useState([])
	const [loading, setLoading] = useState(true)
	const [error, setError] = useState(null)
	const customerId = useCustomerId()

	const printRMA = async (rmaNum) => {
		setError(null)
		try {
			const response = await EpicorRMAService.print(customerId, rmaNum)
			const byteCharacters = atob(response.data)
			const byteNumbers = new Array(byteCharacters.length)
			for (let i = 0; i < byteCharacters.length; i++) {
				byteNumbers[i] = byteCharacters.charCodeAt(i)
			}
			const byteArray = new Uint8Array(byteNumbers)
			const file = new Blob([byteArray], { type: 'application/pdf;base64' })
			const fileURL = URL.createObjectURL(file)
			var link = document.createElement('a')
			link.download = `${currentCompany.company.company1}_RMA_${rmaNum}.pdf`
			link.href = fileURL
			link.click()
			link.remove()
		} catch (error) {
			setError(error.message)
		}
	}

	const fetchRMAs = useCallback(async () => {
		setLoading(true)
		try {
			const response = await EpicorRMAService.findAll(customerId)
			setRMAs(response.data)
		} catch (error) {
			setError(error.message)
		} finally {
			setLoading(false)
		}
	}, [EpicorRMAService, customerId])

	useEffect(() => {
		if (customerId !== undefined) {
			fetchRMAs()
		}
	}, [customerId, fetchRMAs])

	return (
		<div style={containerItem}>
			{error ? (
				<Alert dismissible variant="danger">
					{error}
				</Alert>
			) : null}
			{loading ? (
				<LoadingParagraphs cant={5} />
			) : (
				<RMAsTable rmas={rmas} onPrintRMA={printRMA} />
			)}
		</div>
	)
}

export default RMAsList
