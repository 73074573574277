// @ts-check

import axios from 'axios'
import { useEffect, useMemo } from 'react'
import { useSession } from './useSession'

export function useHttp() {
	const http = useMemo(
		() =>
			axios.create({
				baseURL: `${process.env.REACT_APP_API_BASE_URL}`,
				headers: {
					'Content-Type': 'application/json',
				},
			}),
		[],
	)

	const { user } = useSession()

	// Add a request interceptor to include the x-access-token if available
	useEffect(() => {
		if (!user?.accessToken) {
			return
		}

		const interceptor = http.interceptors.request.use((config) => {
			config.headers['x-access-token'] = user.accessToken
			return config
		})

		return () => {
			http.interceptors.request.eject(interceptor)
		}
	}, [http, user?.accessToken])

	return http
}
