import { Container } from 'react-bootstrap'
import useAuth from 'hooks/useAuth'
import { useSettings } from 'hooks/useSettings'

const Footer = () => {
	const { currentCompany } = useAuth()
	const { settings } = useSettings()
	return (
		<footer className="bg-light text-center text-lg-start">
			<Container fluid className="text-center p-3 small">
				{currentCompany.company.name}
				{settings.companyPrivacy && (
					<>
						{' | '}
						<a href={settings.companyPrivacy} target="_blank" rel="noreferrer">
							Privacy
						</a>
					</>
				)}
				{settings.companyTandC && (
					<>
						{' | '}
						<a href={settings.companyTandC} target="_blank" rel="noreferrer">
							Terms and Conditions
						</a>
					</>
				)}
				<br />© {new Date().getFullYear()}{' '}
				<a href="https://www.elevateerp.com" target="_blank" rel="noreferrer">
					ElevateERP
				</a>
				. All rights reserved.
			</Container>
		</footer>
	)
}

export default Footer
