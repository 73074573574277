// @ts-check

import { createContext, useCallback, useContext, useState } from 'react'

const ImpersonationContext = createContext(
	/** @type {ImpersonationContextValue | undefined} */ (undefined),
)

/**
 * @param {import('react').PropsWithChildren} props
 */
export function ImpersonationProvider({ children }) {
	const [isModalOpen, setIsModalOpen] = useState(false)
	const [impersonatedCustomer, setImpersonatedCustomer] = useState(
		/** @type {Customer | null} */ (null),
	)

	const openImpersonationModal = useCallback(() => {
		setIsModalOpen(true)
	}, [])
	const closeImpersonationModal = useCallback(() => {
		setIsModalOpen(false)
	}, [])
	const unsetImpersonatedCustomer = useCallback(() => {
		setImpersonatedCustomer(null)
	}, [])

	return (
		<ImpersonationContext.Provider
			value={{
				closeImpersonationModal,
				impersonatedCustomer,
				isImpersonationModalOpen: isModalOpen,
				openImpersonationModal,
				setImpersonatedCustomer,
				unsetImpersonatedCustomer,
			}}
		>
			{children}
		</ImpersonationContext.Provider>
	)
}

export function useImpersonation() {
	const context = useContext(ImpersonationContext)
	if (context === undefined) {
		throw new Error(
			'useImpersonation must be used within a ImpersonationProvider',
		)
	}

	return context
}
